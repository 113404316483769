<template>
  <div class="explain">
    <header><i class="el-icon-warning"></i> 数据说明</header>
    <ol>
      <li v-for="(item, index) in list" :key="index">{{ item }}</li>
    </ol>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.explain {
  color: #666666;
  font-size: 14px;
  background-color: #e4ecff;
  border-radius: 6px;
  padding: 16px;
  box-sizing: border-box;
  margin-bottom: 16px;
  header {
    font-weight: bold;
    .el-icon-warning {
      color: #336ffe;
      font-size: 17px;
    }
  }
  ol {
    margin-bottom: 0;
    li {
      margin-bottom: 8px;
    }
  }
}
</style>
