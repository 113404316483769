var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "explain" }, [
    _vm._m(0),
    _c(
      "ol",
      _vm._l(_vm.list, function (item, index) {
        return _c("li", { key: index }, [_vm._v(_vm._s(item))])
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", [
      _c("i", { staticClass: "el-icon-warning" }),
      _vm._v(" 数据说明"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }